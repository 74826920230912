import React, { useState, useContext } from 'react';
import { Link } from 'gatsby';
import get from 'lodash/get';
import styles from './main-navigation.module.less';
import NavigationDrawer from '../navigation-drawer/navigation-drawer';
import { AuthContext } from 'components/helpers/auth-context';

const MainNavigation = (props) => {
  const auth = useContext(AuthContext);
  const logo = "https://res.cloudinary.com/dzykt0nza/image/upload/v1689594928/MCM/mcmLogo_tbjusf.png";
  let navigationItemList = get(props, 'data.navigationItemsList');

  const linkToLandingPage = '/';
  const [navigationDrawerOpen, setNavigationDrawerOpen] = useState(false);
  // const toggleMenuButton = toggleMenuButton.bind(this);
  // const handleToggle = handleToggle.bind(this);
  // const handleLinkClick = handleLinkClick.bind(this);

  function handleToggle() {
    return !navigationDrawerOpen
      ? document.body.classList.remove('is-expanded')
      : document.body.classList.add('is-expanded');
  }

  function toggleMenuButton() {
    setNavigationDrawerOpen(
      (prevState) => ({ navigationDrawerOpen: !prevState.navigationDrawerOpen }),
      () => {
        handleToggle();
      }
    );
  }

  function handleLinkClick() {
    setNavigationDrawerOpen({ navigationDrawerOpen: false }, () => {
      handleToggle();
    });
  }

  let navigationDrawer;

  if (navigationDrawerOpen) {
    navigationDrawer = (
      <NavigationDrawer
        showDrawer={navigationDrawerOpen}
        navigationItemList={navigationItemList}
        onItemClick={() => {
          handleLinkClick();
        }}
      />
    );
  }
  return (
    <nav>
      {logo && (
        <div className={styles.mainNavigation}>
          <div className={styles.topSectionOuterWrapper}>
            {linkToLandingPage && (
              <div className={styles.mainNavigation__imageWrapper}>
                <Link to={linkToLandingPage} onClick={handleLinkClick}>
                  <img className={styles.mainNavigation__logo} src={logo} alt='logo' />
                </Link>
              </div>
            )}
            <button
              className={[styles.mainNavigation__menuButton, 'waves-effect'].join(' ')}
              onClick={toggleMenuButton}
              aria-label='Menu'>
              <i
                className={[navigationDrawerOpen ? 'icon-ic-close' : 'icon-ic-menu', styles.mainNavigation__icon].join(
                  ' '
                )}
              />
            </button>
                {auth.isAuthorize && auth.user.userRoles.find(el => el === 'Admin') && 
                <ul className={styles.navList}>
                  <li key={'admin-panel deskTopNav'} className={styles.listItem}>
                  <Link to={"/admin-panel"} className={styles.listItemLink}>
                    {"MATCHES"}
                  </Link>
                </li>
                <li key={'admin-panel-teams deskTopNav'} className={styles.listItem}>
                  <Link to={"/admin-panel/teams"} className={styles.listItemLink}>
                    {"TEAMS"}
                  </Link>
                </li>
                <li key={'admin-panel-tournaments deskTopNav'} className={styles.listItem}>
                  <Link to={"/admin-panel/tournaments"} className={styles.listItemLink}>
                    {"TOURNAMENTS"}
                  </Link>
                </li>
                <li key={'admin-panel-leagues deskTopNav'} className={styles.listItem}>
                  <Link to={"/admin-panel/leagues"} className={styles.listItemLink}>
                    {"LEAGUES"}
                  </Link>
                </li>
                <li key={'admin-panel-betting deskTopNav'} className={styles.listItem}>
                  <Link to={"/admin-panel/betting"} className={styles.listItemLink}>
                    {"BETTING"}
                  </Link>
                </li>
                <li key={'admin-panel-sorting deskTopNav'} className={styles.listItem}>
                  <Link to={"/admin-panel/sorting"} className={styles.listItemLink}>
                    {"SORTING"}
                  </Link>
                </li>
                <li key={'admin-panel-images deskTopNav'} className={styles.listItem}>
                  <Link to={"/admin-panel/stadiums"} className={styles.listItemLink}>
                    {"STADIUMS"}
                  </Link>
                </li>
                <li key={'admin-panel-images deskTopNav'} className={styles.listItem}>
                  <Link to={"/admin-panel/groups"} className={styles.listItemLink}>
                    {"GROUPS"}
                  </Link>
                </li>
              </ul>}
              {!auth.isAuthorize &&  <ul className={styles.navList}>
                <li key={'login deskTopNav'} className={styles.listItem}>
                  <Link to={"/login"} className={styles.listItemLink}>
                    {"LOGIN"}
                  </Link>
                </li>
              </ul>}
          </div>
          <div className={styles.mainNavigationSpacer}></div>
        </div>
      )}
      {navigationDrawer}
    </nav>
  );
};


export default MainNavigation;
