import React, { useState } from "react";
import styles from './tournament-element.module.less';
import Modal from 'components/block-elements/modal/modal';
import TournamentSettingsElement from 'components/block-elements/tournament-settings-element/tournament-settings-element';
const TournamentElement = (props) => {
    const [modalIsOpen, setIsOpen] = useState(false);

    const closeModal = (event) => {
        setIsOpen(false);
    }

    const openModal = (event) => {
        setIsOpen(true);
    }

    const updateData = () => {
        props.updateData();
    }

    return ( 
    <div className={styles.tournamentBox}>
        <div onClick={openModal} className={styles.mainContainer}>
            <div className={styles.flex}>
                <div>
                    <img className={styles.tournamentImage} src={props.tournamentLogoId}></img>
                </div>

                <div className={styles.infoContainer}>
                    <div className={styles.infoBlock}>
                        <div className={styles.info}>
                            <div className={styles.infoDesctiptionText}>Tournament Name: </div>
                            <div>{props.name}</div>
                        </div>
                    </div>

                    <div className={styles.infoBlock}>
                        <div className={styles.info}>
                            <div className={styles.infoDesctiptionText}>Tournament Id: </div>
                            <div>{props.id}</div>
                        </div>
                    </div>

                    <div className={styles.infoBlock}>
                        <div className={styles.info}>
                            <div className={styles.infoDesctiptionText}>Tournament Sport: </div>
                            <div>{props.sportName}</div>
                        </div>
                    </div>

                    <div className={styles.infoBlock}>
                        <div className={styles.info}>
                            <div className={styles.infoDesctiptionText}>Display name: </div>
                            <div>{props.displayName}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <img className={styles.tournamentTvImage} src={props.tournamentTvLogoId}></img>
            </div>
        </div>
        {modalIsOpen && <Modal setIsOpen={closeModal}>
            <TournamentSettingsElement updateData={updateData} closeModal={closeModal}  {...props}/>
        </Modal>}
    </div>)
}

export default TournamentElement;