import React, { useEffect, useState } from "react";
import toast, { Toaster } from 'react-hot-toast';
import styles from './tournament-settings-element.module.less';
import Modal from 'components/block-elements/modal/modal';
import AdminImageComponent from '../admin-images/admin-images';
import TournamentStandingService from 'services/tournament-standings-service';

const TournamentSettingsElement = (props) => {
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isTournamentModalOpen, setIsTournamentModalOpen] = useState(false);
    const [tournament, setTournament] = useState({
        id: null,
        name: '',
        sportId: '',
        sportName: '',
        tournamentCategoryId: '',
        currentSeasonId: '',
        tournamentLogoId: '',
        tournamentTvLogoId: '',
        displayName: null,
        isVarInclude: false,
        isCustomLineups: false,
        teamTableColors: [{
            color: '',
            leagueName: props.id,
            teamsEnd: '',
            teamsStart: '',
            tournamentName: ''
        }]
    });
    const [newTvImage, setNewTvImage] = useState({
        id: null, 
        url: '',
        name: '',
        type: 0,
    });

    const [newTournamentImage, setNewTournamentImage] = useState({
        id: null, 
        url: '',
        name: '',
        type: 0,
    });

    const openModal = () => {
        return setIsModalOpen(true);
    }

    useEffect(() => {
        setTournament(props);
    },[])

    const changeHandlerDisplayName = (event) => {
        setTournament({...tournament, displayName: event.target.value });
    }
    
    const setIsTournamentModalLogoOpen = () => {
        setIsTournamentModalOpen(true);
    }

    const setIsTournamentModalLogoClose = () => {
        setIsTournamentModalOpen(false);
    }

    const closeModal = () => {
        return setIsModalOpen(false);
    }

    const save = async() => {
        setLoading(true);
        let model = {
            tournamnetId: tournament.id,
            tvLogoImageId: newTvImage.id,
            tournamentLogoId: newTournamentImage.id,
            displayName: tournament.displayName,
            isVarInclude: tournament.isVarInclude,
            isCustomLineups: tournament.isCustomLineups,
            teamTableColors: tournament.teamTableColors
        };
        await TournamentStandingService.changeTournamentsLogo(model)
        .then(res => {
            props.updateData();
        })
        .catch(err => {
        });
        setLoading(false);
    }

    const setStartTeamPosition = (e, index) => {
        let colors = tournament.teamTableColors;

        colors.map((el, i) => 
            i == index ? el.teamsStart = e.target.value : el
        );

        setTournament({ ...tournament, teamTableColors: colors });
    }

    const setEndTeamPosition = (e, index) => {
        let colors = tournament.teamTableColors;

        colors.map((el, i) => 
            i == index ? el.teamsEnd = e.target.value : el
        );

        setTournament({ ...tournament, teamTableColors: colors });
    }

    const setTournamentTeamName = (e, index) => {
        let colors = tournament.teamTableColors;

        colors.map((el, i) => 
            i == index ? el.tournamentName = e.target.value : el
        );

        setTournament({ ...tournament, teamTableColors: colors });
    }

    const setTeamColor = (e, index) => {
        let colors = tournament.teamTableColors;

        colors.map((el, i) => 
            i == index ? el.color = e.target.value : el
        );

        setTournament({ ...tournament, teamTableColors: colors });
    }

    const addTeamColor = () => {
        let colors = tournament.teamTableColors;
        colors.push({
            color: '',
            leagueName: props.id,
            teamsEnd: 0,
            teamsStart: 0,
            tournamentName: ''
        })

        setTournament({ ...tournament, teamTableColors: colors });
    }

    const deleteElem = (index) => {
        let colors = tournament.teamTableColors;
        colors.splice(index, 1);

        setTournament({ ...tournament, teamTableColors: colors });
    }

    return(
        <>
            <div className={styles.buttonContainer}>
                <button className={styles.closeButton} onClick={props.closeModal}>X</button>
            </div>
            <div className={styles.mainContainer}>
                <div className={styles.childContainer}>
                    <span>Tournament Id</span>
                    <input
                        name='Tournament Id'
                        value={tournament.id}
                        readOnly
                        className={styles.input}
                        type='text'>
                    </input>
                    <span>Tournament Name</span>
                    <input
                        name='Tournament Name'
                        value={tournament.name}
                        readOnly
                        className={styles.input}
                        type='text'>
                    </input>
                    <span>Tournament Display Name</span>
                    <input
                        name='Tournament Display Name'
                        value={tournament.displayName}
                        className={styles.input}
                        onChange={changeHandlerDisplayName}
                        type='text'>
                    </input>
                    <span>Tournament Sport Id</span>
                    <input
                        name='Tournament Sport Id'
                        value={tournament.sportId}
                        readOnly
                        className={styles.input}
                        type='text'>
                    </input>
                    <span>Tournament Sport Name</span>
                    <input
                        name='Tournament Sport Name'
                        value={tournament.sportName}
                        readOnly
                        className={styles.input}
                        type='text'>
                    </input>
                    { tournament.sportId == 'e:sport:1' && <div className={styles.checkBoxContainer}>
                        <input name="varIcnlude" className={styles.varInclude} checked={tournament.isVarInclude} defaultChecked={tournament.isVarInclude} onChange={() => setTournament({ ...tournament, isVarInclude: !tournament.isVarInclude })} type="checkbox"></input>
                        <label>Var include</label>
                    </div> }

                    {tournament.sportId == 'e:sport:1' && <div className={styles.checkBoxContainer}>
                        <input checked={tournament.isCustomLineups} className={styles.varInclude} defaultChecked={tournament.isCustomLineups} onChange={() => setTournament({ ...tournament, isCustomLineups: !tournament.isCustomLineups })} type="checkbox"></input>
                        <label>Custom Lineups</label>
                    </div>}

                    <div style={{marginTop: '14px'}}>
                        <span>Tournament Table Colors</span>
                        {tournament.teamTableColors.length > 0 && 
                            tournament.teamTableColors.map( (el, index) => <div style={{marginBottom: '4px'}} key={index}>
                                <input className={styles.lowWidthInput} type="number" placeholder="Start place" value={el.teamsStart} onChange={(e) => setStartTeamPosition(e, index)}></input>
                                <input className={styles.lowWidthInput} type="number" placeholder="End place" value={el.teamsEnd} onChange={(e) => setEndTeamPosition(e, index)}></input>
                                <input type="text" placeholder="Next step Tournamnet name" value={el.tournamentName} onChange={(e) => setTournamentTeamName(e, index)}></input>
                                <input className={styles.width100} type="text" placeholder="Color" style={{background: el.color}} value={el.color} onChange={(e) => setTeamColor(e, index)}></input>
                                <button style={{width: '61px'}} onClick={() => deleteElem(index)}>Del</button>
                            </div>) }
                            <div><button style={{width: '100%'}} onClick={addTeamColor}>Add</button></div>
                    </div>

                    <span>Tv Logo</span>
                    <div className={styles.tvLogoContainer}>
                        <img className={styles.modalTeamImage} src={newTvImage?.url?.length > 0 ? newTvImage?.url : props.tournamentTvLogoId}></img>

                        <button className={styles.deleteBtn} onClick={() => openModal()}>
                            Change Tv Logo
                        </button>
                    </div>

                    <span>Tournament Logo</span>
                    <div className={styles.tvLogoContainer}>
                        <img className={styles.modalTeamImage} src={newTournamentImage?.url?.length > 0 ? newTournamentImage?.url : props.tournamentLogoId}></img>

                        <button className={styles.deleteBtn} onClick={() => setIsTournamentModalLogoOpen()}>
                            Change Tournament Logo
                        </button>
                    </div>
                    {!loading && <button disabled={loading} onClick={save} className={styles.saveButton}>Save</button>}
                    <Toaster position='top-left' reverseOrder={true} />
                </div>
                {isModalOpen && <Modal color="aliceblue" zIndex={10} setIsOpen={closeModal}>
                    <AdminImageComponent imageType={2} setImage={setNewTvImage} onClose={closeModal}  />
                </Modal> }
                {isTournamentModalOpen && <Modal color="aliceblue" zIndex={10} setIsOpen={setIsTournamentModalLogoClose}>
                    <AdminImageComponent imageType={5} setImage={setNewTournamentImage} onClose={setIsTournamentModalLogoClose}  />
                </Modal>}
            </div>
        </>
    )
}

export default TournamentSettingsElement;