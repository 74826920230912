import React from 'react';
import { useAuth } from 'components/hooks/auth.hook.js';
import { AuthContext } from 'components/helpers/auth-context.js';
import PageMainNavigation from 'components/navigations/page-main-navigation/main-navigation';
import '../styles/global.css'

// import './layout.less';
// import '../styles/typography.less';
// import '../styles/icons/style.less';
// import '../styles/animations.less';
// import 'components/block-elements/ui-btn/ui-btn.less';
// import 'components/block-elements/ui-select/ui-select.less';
// import 'components/block-elements/standings-table-colors/standings-table-colors.less';
// import 'components/block-elements/tournament-logo/tournament-logo.less';
// import 'components/page.less';

const PageLayout = (props) => {
    const { token, login, logout, userId, ready, user, isAuthorize } = useAuth();
    const isAunthenticated = !!token;

    return (
        <AuthContext.Provider value={{ token, login, logout, userId, isAunthenticated, user, isAuthorize, ready }}>
            { ready && <div id='Layout' className={props.className}>
                <PageMainNavigation/>
                {isAuthorize ? props.children : <span>Please login to your account to be able to see the admin panel!</span>}
            </div>}
        </AuthContext.Provider>
    );
}

export default PageLayout;