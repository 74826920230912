import React, { useEffect, useState } from "react";
import styles from './admin-tournament-block.module.less';
import TournamentService from 'services/tournament-standings-service';
import Loader from 'components/block-elements/loader-css/loader-css';
import { navigate } from 'gatsby';
import TournamentElement from "components/block-elements/tournament-element/tournament-element";

const AdminTournamentBlock = (props) => {

    const [filter, setFilter] = useState({
        sportIds: [],
        tournamentName: props.searchText ?? '',
        page: props.page > 0 ? props.page - 1 : 0
    });
    const [tournaments, setTournaments] = useState([]);
    const [loading, setLoading] = useState(false);
    const [tournamentName, setTournamentName] = useState(props.searchText ?? '');
    const [pagesCount, setPagesCount] = useState(null);

    const getFilterTournaments = async () => {
        setLoading(true);
        await TournamentService.getFilterTournamets(filter)
            .then(res => {
                setTournaments(res.data.data);
                setPagesCount(Array.from(Array(Math.ceil(res.data.total / 10)).keys()));
                setLoading(false);
            })
            .catch(err => {
            });
    }

    useEffect(() => {
        getFilterTournaments();
    }, [filter])

    const searchClick = () => {
        setFilter({...filter, tournamentName: tournamentName, page: 0});
        let searchParams = new URLSearchParams(props.searchParam);
        searchParams.delete('searchText');
        searchParams.append("searchText", tournamentName);
        searchParams.delete('page');
        searchParams.append("page", 1);
        navigate(`/admin-panel/tournaments?${searchParams.toString()}`);
    }

    const changeHandlerSearch = (event) => {
        setTournamentName(event.target.value);
    };

    const clearSearch = () => {
        setFilter({...filter, tournamentName: '', page: 0});
        let searchParams = new URLSearchParams(props.searchParam);
        searchParams.delete('searchText');
        searchParams.delete('page');
        searchParams.append("page", 1);
        setTournamentName('');
        navigate(`/admin-panel/tournaments?${searchParams.toString()}`);
    }

    const chagePage = (event) => {
        setFilter({ ...filter, page: parseInt(event.target.name) });
        let searchParams = new URLSearchParams(props.searchParam);
        searchParams.delete('searchText')
        searchParams.append('searchText', filter.tournamentName)
        searchParams.delete('page');
        searchParams.append("page", parseInt(event.target.name) + 1);
        navigate(`/admin-panel/tournaments?${searchParams.toString()}`);
    };

    return(
        <div>
            {loading && <Loader/>}
            <div className={styles.searchBlock}>
                <input
                name='searchText'
                placeholder='Search by tournament name or Id'
                value={tournamentName}
                onInput={changeHandlerSearch}
                className={styles.input}
                type='text'></input>
                <button disabled={tournamentName == null || tournamentName.length < 1} className={styles.searchButton} onClick={() => searchClick()}>
                Search
                </button>
                <button disabled={tournamentName == null || tournamentName.length < 1} onClick={() => clearSearch()}>Clear</button>
            </div>
            {!loading && tournaments?.length > 0 && tournaments.map(tournament => 
                <TournamentElement key={tournament.id} updateData={getFilterTournaments} {...tournament}/>
            )}
            <div className={styles.flexButtonGroup}>
                <div className={styles.buttonsGroups}>
                {pagesCount?.length > 0 &&
                pagesCount.map((el, i) => {
                    return (
                    <button
                        className={`${el == filter.page ? styles.activePagButton : ''} ${styles.button}`}
                        name={el}
                        onClick={chagePage}>
                        {el + 1}
                    </button>
                    );
                })}
            </div>
            </div>
        </div>
    )
}

export default AdminTournamentBlock;