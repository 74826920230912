import axios from 'components/helpers/axios';

class UserProfileService {
  login(model) {
    return axios.post(`api/auth/login`, model);
  }

  getUSerProfile(token) {
    return axios.get('api/UserProfile/getUserProfile');
  }
}

const service = new UserProfileService();
export default service;
