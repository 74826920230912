import axios from 'components/helpers/axios';

const url = '/api/images';

class ImagesService {
    UploadNewFile( file, type, fileName ){
        return axios.post(`${url}/UploadNewFile?type=${type}&name=${fileName}`, file);
    }

    GetAllImages() {
        return axios.get(`${url}/GetAllImages`)
    }

    GetFilterImagesAsync( filter ) {
        return axios.post(`${url}/GetFilterImagesAsync`, filter);
    }

    deleteImage(id, imageId) {
        return axios.delete(`${url}/deleteImage?id=${id}&imageId=${imageId}`)
    }

}

const singleton = new ImagesService();
export default singleton;
