import React from 'react';
import styles from './loader-css.module.less';

const LoaderCSS = props => {
  return (
    <div className={[props.relative ? styles.loaderRelative : styles.loaderWrapper, props.positionTop ? styles._top : ''].join(' ')}>
      <div className={[props.relative && styles.center, styles.loader].join(' ')}></div>
    </div>
  );
};

export default LoaderCSS;
