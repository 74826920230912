import { useState, useCallback, useEffect } from 'react';
import Cookies from 'universal-cookie';
import UserProfileService from 'services/user-profile-service';
import axios from 'components/helpers/axios';

const cookies = new Cookies();

export const useAuth = () => {
  const [token, setToken] = useState(null);
  const [ready, setReady] = useState(false);
  const [user, setUser] = useState(null);
  const [userId, setUserId] = useState(null);
  const [isAuthorize, setIsAuthorize] = useState(false);

  const login = useCallback(async (jwtToken) => {
    setToken(jwtToken);
    cookies.set('auth._token.local', jwtToken, { path: '/' });
    axios.defaults.headers.Authorization = `Bearer ${jwtToken}`;
    await UserProfileService.getUSerProfile(jwtToken)
      .then((res) => {
        setUserId(res.data.id);
        setUser(res.data);
        setIsAuthorize(true);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          logout();
          setIsAuthorize(false);
        }
      });
  }, []);

  const logout = useCallback(() => {
    setToken(null);
    setUserId(null);
    cookies.remove('auth._token.local');
  }, []);

  useEffect(() => {
    let tokenCookies = cookies.get('auth._token.local');
    if (tokenCookies && user == null) {
      login(tokenCookies);
    }
    setReady(true);
  }, [login]);

  return { login, logout, user, isAuthorize, token, userId, ready };
};
