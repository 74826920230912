import React, { useContext, useEffect, useRef, useState } from 'react';
import styles from './modal.module.less';

const Modal = (props) => {
  return (
    <>
      <div className={styles.darkBG} onClick={() => props.setIsOpen(false)} />
      <div className={styles.centered} style={{zIndex: props.zIndex ?? 9}}>
        <div className={styles.modal} style={{background: props.color ?? 'white'}}>
          {props.children}
        </div>
      </div>
    </>
  );
};

export default Modal;
