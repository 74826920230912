import React, {useState, useEffect} from "react";
import ImageService from 'services/image-services';
import styles from './admin-images.module.less';

const AdminImagesBlock = (props) => {
    const [image, setImage] = useState(null);
    const [imageList, setImageList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [previewUploadImage, setPreviewUploadImage] = useState(null)
    const [filterModel, setFilterModel] = useState({
        name: '',
        type: props.imageType
    });

    const fileSelectedHendler = event => {
        setImage(event.target.files[0]);
        setPreviewUploadImage(URL.createObjectURL(event.target.files[0]));
    }

    const fileUploadData = async() => {
        setLoading(true);
        const fd = new FormData();

        fd.append('files', image, image.name);

        await ImageService.UploadNewFile(fd, filterModel.type, image.name);

        await getFilterImages();

        setImage(null);
        setPreviewUploadImage(null);
        setLoading(false);
    }

    useEffect(() => {
        getFilterImages();
    }, [])

    const getFilterImages = async() => {
        await ImageService.GetFilterImagesAsync(filterModel)
            .then(res => {
                setImageList(res.data);
            }).catch(err => {
                
            })
    }

    const returnImage = (image) => {
        props.setImage(image);
        props.onClose();
    }

    const deleteImage = async(image) => {
        let result = window.confirm('Are you sure you want to delete this image');

        if(result)
        {
            await ImageService.deleteImage(image.id, image.imageId)
                .then(async (res) => {
                    await getFilterImages();
                })
                .catch(err => {
    
                })
        }
    } 

    return(
        <div className={styles.mainContainer}>
            <div className={styles.uploadContainer}>
                <input type="file" onChange={fileSelectedHendler}></input>
                {previewUploadImage?.length > 0 && <img className={styles.previewImage} src={previewUploadImage}></img>}
                {!loading && <button onClick={fileUploadData}>Upload</button> }
            </div>
            {props.setIsOpen && <div className={styles.closeBlock}>
                <button className={styles.closeButton} onClick={() => props.setIsOpen(false)}>X</button>
            </div>}
            <div className={styles.subtitle}>
                <div className={styles.subtitleTextBlock}>
                    <h4>Images List</h4>
                </div>
            </div>
            <div className={styles.childContainer}>
                <div>
                    {imageList.map(el => 
                        <button 
                            style={{backgroundImage: `url(${el.url})`}} 
                            onClick={ () => returnImage(el) } 
                            className={styles.imageButton} 
                            alt={el.name}>
                                <div className={styles.deleteButton}>
                                    <button onClick={(evt) => {evt.stopPropagation(); evt.nativeEvent.stopImmediatePropagation(); deleteImage(el)}}>D</button>
                                </div>
                        </button>)}
                </div>
            </div>
        </div>
    )
} 

export default AdminImagesBlock;