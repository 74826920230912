import MatchHelper from 'components/helpers/match-helper';
import TeamHelper from 'components/helpers/team-helper';
import axios from 'components/helpers/axios';

const url = 'api/tournament';
const standingsUrl = 'api/standings';

class TournamentStandingsService {
  getStandingsForTournament(tournamentId, live = true) {
    return axios.get(`${url}/standings?tournamentId=${tournamentId}&live=${live}`)
      .then((response) => TeamHelper.updateTournamentStandingGroupWithAttributesFromTeamStore(response.data))
      .catch((error) => {})
  }

  getAllTournamets() {
    return axios.get(`${url}/getAllTournamets`);
  }

  getAllLeagues() {
    return axios.get(`${url}/getAllTournametsWithCategories`);
  }

  getAllTournamentsCategories() {
    return axios.get(`${url}/getAllTournametsCategories`);
  }

  changeTournamentsLogo(model) {
    return axios.put(`${url}/changeTournamentsLogo`, model);
  }

  changeLeague(model) {
    return axios.put(`${url}/changeLeague`, model);
  }

  getFilterTournamets(filter) {
    return axios.post(`${url}/getFilterTournamets`, filter);
  }

  getFilterLeagues(filter) {
    return axios.post(`${url}/getFilterLeagues`, filter);
  }

  getBookingStandingsForTournament(tournamentId, sportId) {
    return MatchHelper.isMatchHockey(sportId)
      ? this.getBookingStandingsForTournamentHockey(tournamentId)
      : this.getBookingStandingsForTournamentSoccer(tournamentId);
  }

  getBookingStandingsForTournamentSoccer(tournamentId) {
    //type parameter doesn't matter for response but is required, maybe will get updated later.
    return axios.get(`${url}/ranking?tournamentId=${tournamentId}&type=${'cards'}`)
      .then((response) => TeamHelper.updateTournamentBookingPlayerWithAttributesFromTeamStore(response.data))
      .catch((error) => {})
  }
  getBookingStandingsForTournamentHockey(tournamentId) {
    return axios.get(`${url}/rankinghockey?tournamentId=${tournamentId}&type=penalties`)
      .then((response) => TeamHelper.updateTournamentRankingPlayerWithAttributesFromTeamStore(response.data))
      .catch((error) => {})
  }

  getScoringStandingsForTournament(tournamentId, sportId) {
    return MatchHelper.isMatchHockey(sportId)
      ? this.getPointsStandingsInTournamentHockey(tournamentId)
      : this.getGoalsScoringStandingInTournamentSoccer(tournamentId);
  }

  getPointsStandingsInTournamentHockey(tournamentId) {
    return axios.get(`${url}rankinghockey?tournamentId=${tournamentId}&type=points`)
      .then((response) => TeamHelper.updateTournamentRankingPlayerWithAttributesFromTeamStore(response))
      .catch((error) => {})
  }

  getGoalsScoringStandingInTournamentSoccer(tournamentId) {
    return axios.get(`${url}/ranking?tournamentId=${tournamentId}&type=goals`)
      .then((response) => TeamHelper.updateTournamentRankingPlayerWithAttributesFromTeamStore(response))
      .catch((error) => {})
  }

  getTopScorers(eventId, sportId) {
    return axios.get(`${standingsUrl}/top-scorers?sportEventId=${eventId}&sportId=${sportId}`);
  }

  getTopScorersPage(eventId, sportId) {
    return axios.get(`${standingsUrl}/top-scorers-page?sportEventId=${eventId}&sportId=${sportId}`);
  }

  getCardsScore(eventId, isHockey) {
    return axios.get(`${standingsUrl}/card-score?sportEventId=${eventId}&isHockey=${isHockey}`);
  }

  getCardsScorePage(eventId) {
    return axios.get(`${standingsUrl}/card-score-page?sportEventId=${eventId}`);
  }

  getLeagueTable(eventId, sportId, isLiagueTablePage = false) {
    return isLiagueTablePage
      ? axios.get(`${standingsUrl}/live-league-table-page?seasonId=${eventId}&sportId=${sportId}`)
      : axios.get(`${standingsUrl}/live-league-table?sportEventId=${eventId}&sportId=${sportId}`);
  }

  saveTournamentCategoryOrder(id, order) {
    return axios.put(`${url}/saveTournamentCategoryOrder?id=${id}&order=${order}`)
  }
}

const service = new TournamentStandingsService();
export default service;
